.app_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 15px;

    .footer_text_wrap {
        ul {
            padding: 0px;
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 0px;

            li {
                padding-right: 15px;

                a {
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 12px;
                    color: white;
                    text-shadow: 0px 0px 5px black;
                }
            }
        }
    }

    .footer_icn_wrap {
        ul {
            padding: 0px;
            list-style: none;
            display: flex;
            align-items: center;

            li {
                padding-left: 15px;

                a {
					color: white;
					background-color: rgb(0 179 233);
					padding: 11px 14px;
					border-radius: 100px;
					font-size: 20px;
                }
            }

        }
    }
}